<template>
  <!-- Header -->
  <div ref="navBar" class="nav-bar p-3 pb-2 pb-md-3">
    <div class="row justify-content-between">
      <div class="col-auto col-md-6 home-btn">
        <router-link class="not-gray" to="/"
          >SSC<span class="current-route">{{ currentRoute }}</span>
        </router-link>
      </div>
      <!-- Desktop -->
      <div class="col-4 d-none d-md-block">
        <span class="about-btn" @click="toggleAbout">About</span>
      </div>
      <div class="col-1 d-none d-md-block p-0">
        <a class="not-gray" :href="'mailto:' + email">Contact</a>
      </div>
      <!-- Mobile -->
      <div class="col-auto about-btn d-md-none d-block text-end">
        <span v-if="currentRoute === ''" @click="toggleAbout"
          >About / Contact</span
        >
        <span v-else @click="toggleAbout">A/C</span>
      </div>
    </div>
  </div>
  <!-- About pop-out -->

  <div
    id="about"
    :style="`background-color:${basecolor}`"
    class="about-desktop d-none d-md-flex"
  >
    <div class="row justify-content-between p-3">
      <div class="col-6">
        <div class="h-100 col-12 d-flex justify-content-between flex-column">
          <prismic-rich-text :field="abouttext.partOne" />
          <div>
            <prismic-rich-text class="pt-3" :field="abouttext.partTwo" />
            <p class="pb-0 mb-0">
              <span><a style="color: white" :href="arena.url">Are.na</a></span>
              /

              <span>
                <a style="color: white" :href="linkedin.url">LinkedIn</a></span
              >
            </p>
          </div>
        </div>
      </div>
      <div @click="toggleAbout" class="col-4 close-btn">
        <span class="about-btn">Close</span>
      </div>

      <div class="col-1 p-0">
        <a class="not-gray" style="color: white" :href="'mailto:' + email"
          >Contact</a
        >
      </div>
    </div>
  </div>
  <!-- Mobile -->
  <transition name="fade">
    <div v-if="showAbout" class="about-mobile d-block d-md-none p-3">
      <div class="row justify-content-between d-flex mobile-container">
        <div class="col-auto home-btn pb-3">
          <router-link to="/">SSC</router-link>
        </div>
        <!-- Mobile -->
        <div @click="toggleAbout" class="col-4 text-end about-btn pb-3">
          Close
        </div>
        <div :style="'height:' + fillerHeight" class="filler col-12"></div>
        <div class="col-12 py-3">
          <prismic-rich-text :field="abouttext.partOne" />
          <prismic-rich-text :field="abouttext.partTwo" />
          <p style="color: black !important">
            <span
              ><a style="color: black !important" :href="arena.url"
                >Are.na</a
              ></span
            >
            /

            <span>
              <a style="color: black !important" :href="linkedin.url"
                >LinkedIn</a
              ></span
            >
          </p>
        </div>
        <span class="mt-2">&copy; 2012-{{ new Date().getFullYear() }}</span>
      </div>
    </div>
  </transition>
  <!-- Overlay -->
  <transition name="fade">
    <div v-if="showAbout" class="about-overlay d-none d-md-block"></div
  ></transition>
  <div :style="'height:' + fillerHeight" class="filler"></div>
</template>
<script>
export default {
  inject: ["store"],
  props: ["abouttext", "email", "basecolor", "arena", "linkedin"],
  data() {
    return {
      showAbout: this.store.showAbout,
      fillerHeight: "calc(50vh - 4.65rem)",
      onMobile: true,
    };
  },
  methods: {
    toggleAbout() {
      this.store.toggleAbout()
      if (this.showAbout) {
        document.getElementById("about").style.transform = "translateY(0)";
      } else {
        document.getElementById("about").style.transform = "translateY(-100%)";
      }
    },
    calcFiller() {
      if (window.innerWidth < 768) {
        this.fillerHeight = "calc(50vh - 4.65rem)";
      } else {
        this.fillerHeight =
          document.getElementById("about").offsetHeight - 50 + "px";
      }
    },
  },

  computed: {
    currentRoute() {
      let uidExtension = "";
      if (this.$route.params.uid) {
        uidExtension = this.$route.params.uid;
      }
      if (uidExtension === "commerical-work" && window.innerWidth < 768) {
        return " / Works";
      } else return this.$route.name + uidExtension.replace(/-/g, " ");
    },
  },
  mounted() {
    window.innerWidth < 768 ? (this.onMobile = true) : (this.onMobile = false);
    const navbar = this.$refs.navBar;
    let lastScrollTop;
    window.addEventListener("scroll", () => {
      if (!this.showAbout && this.onMobile) {
        let scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
        if (scrollTop > lastScrollTop && scrollTop > 10) {
          navbar.style.transform = "translateY(-100%)";
        } else {
          navbar.style.transform = "translateY(0)";
        }
        lastScrollTop = scrollTop;
      }
    });
    window.addEventListener("resize", () => {
      window.innerWidth < 768
        ? (this.onMobile = true)
        : (this.onMobile = false);
    });
  },
};
</script>
<style scoped>
/* .mobile-container {
  margin-top: 2px;
} */
.show-about {
  top: 0 !important;
}
/* .current-route {
  color: #1e1e1e;
} */
.about-desktop {
  line-height: 0.973rem;
  height: auto;
  min-height: calc(50vh - 1.5rem);
  transform: translateY(-100%);
  background: #b39884;
  position: fixed;
  z-index: 2;
  width: 100%;
  left: 0;
  right: 0;
  color: white;
  transition: transform ease 800ms;
}
.about-mobile {
  overflow: scroll;
  font-size: 0.75rem;
  line-height: 0.973rem;
  min-height: 100%;
  height: auto;
  top: 0;
  bottom: 0;
  background: #ffffff;
  position: fixed;
  z-index: 2;
  width: 100%;
  left: 0;
  right: 0;
  color: black;
  transition: top ease 800ms;
}
.about-overlay {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0.8;
  background: #ffffff;
}
.close-btn {
  color: #fff;
}

.nav-bar {
  padding-top: 0.9rem !important;
  font-size: 0.75rem;
  width: 100%;
  top: 0rem;
  left: 0;
  right: 0;
  background: white;
  z-index: 1;
  position: fixed;
  transition: transform 250ms ease;
  transform: translateY(0);
}
.about-btn:hover {
  cursor: pointer;
  color: #1e1e1e;
}
a {
  color: #1e1e1e;
  text-decoration: none;
}
a:hover {
  cursor: pointer;
  color: #1e1e1e;
}

@media only screen and (min-width: 768px) {
  .about-btn:hover {
    cursor: pointer;
    color: rgb(166, 166, 166);
  }
  a:hover {
    color: rgb(166, 166, 166);
    text-decoration: none;
  }
  .nav-bar {
    position: relative;
  }
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active {
  transition: all 800ms ease;
}

.fade-leave-active {
  transition: all 800ms ease;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 0.8;
}
</style>