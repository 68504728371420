<template>
  <footer class="p-3">
    <div class="row h-100 align-items-end justify-content-between d-flex">
      <div class="col-6 d-none d-md-block">{{ email }}</div>
      <div class="col-6 d-block d-md-none">
        @ 2018–{{ new Date().getFullYear() }}
      </div>
      <div @click="goToTop" class="col-6 d-block d-md-none text-end top-btn">
        TOP
      </div>
      <div class="col-3 d-none d-md-block">
        <div class="top-btn" @click="toggleAbout">About</div>
        <!-- <a target="_blank" :href="arena.url">Are.na</a> /
        <a target="_blank" :href="linkedin.url">LinkedIn</a> -->
      </div>
      <div class="col-2 p-0 text-end pe-2 d-none d-md-block">
        @ 2018–{{ new Date().getFullYear() }}
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  inject: ["store"],
  props: ["arena", "linkedin", "email"],
  data() {
    return {
      showAbout:this.store.showAbout
    };
  },
  methods: {
    goToTop() {
      window.scrollTo(0, 0);
    },
    toggleAbout() {
      this.store.toggleAbout()
      if (this.showAbout) {
        document.getElementById("about").style.transform = "translateY(0)";
      } else {
        document.getElementById("about").style.transform = "translateY(-100%)";
      }
    },
  },
};
</script>
<style scoped>
a {
  
  color: rgb(166, 166, 166);
  text-decoration: none;
}
a:hover {
  color: #1e1e1e;
 cursor: pointer;
}

@media only screen and (min-width: 768px) {
  a:hover {
   
    color: #1e1e1e;
  }
}
footer {
  width: 100%;
  margin-top: auto;
  color: rgb(166, 166, 166);
  /* height: 27vh; */
}
.top-btn:hover {
  cursor:pointer;
}
</style>