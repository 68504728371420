// import { reactive } from 'vue'
// export const store = {
//   state: reactive({
//     movies: ['Harry Potter', 'BAHUBALI']
//   }),
//   addMovie (newMovie) {
//     this.state.movies.push(newMovie)
//   }
// }

import { ref } from 'vue'

const showAbout= ref(false)
function toggleAbout () {
  showAbout.value = !showAbout.value
}

export default { showAbout, toggleAbout }
