<template>
  <div id="project-nav" class="p-3">
    <div class="row d-flex align-items-start justify-content-between">
      <div class="col-md-4 col-12 order-1 order-md-2">
        <span @mouseover="previewProjectEnter(project, index)" @mouseleave="previewProjectLeave" class="index-nav"
          @click="changeProject(project)" v-for="(project, index) in projects" :key="index"
          :class="[hoverPreview ? 'index-nav-toggle' : '']"><span class="index-nav-toggle-special" :class="[index === currentProjectIndex ? 'current-project' : ''
          ]">{{
            index + 1 }} </span>
          <span v-if="index + 1 < projects.length"> / </span></span>

      </div>


      <div v-if="loaded && !hoverPreview" class="col-md-6 col-12 home-btn order-2 order-md-1">
        {{ title }} /
        {{ date }}
      </div>
      <div v-if="loaded && hoverPreview" class="col-md-6 col-12 home-btn order-2 order-md-1">
        {{ currentTitle }} /
        {{ currentDate }}
      </div>

      <div @click="toggleInfo" :class="[showInfo ? 'strikethrough' : '', hoverPreview ? 'info-opacity' : '']"
        class="col-1 p-0 d-none d-md-block info-btn order-md-3">
        Information
      </div>
      <div class="col-12"></div>
    </div>
    <div class="preview-img">
      <div class="container-fluid d-flex justify-content-center align-items-top">
        <div class="image" v-show="index === currentHover" v-for="(p, index) in projects" :key="'project-' + index">


          <img v-if="p.primary.preview_image.url" class="img-fluid" :src="p.primary.preview_image.url + '?&q=30'"
            :height="p.primary.preview_image.dimensions.height" :width="p.primary.preview_image.dimensions.width"
            alt="">

          <div v-else class="h-100">
            <img v-if="p.items[0].image.url" class="img-fluid" :src="p.items[0].image.url + '?&q=30'" alt="">
            <video v-else class="lazyload img-fluid" playsinline preload="none" muted="" data-autoplay="" loop
              :src="p.items[0].video.url"></video>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  props: ["loaded", "title", "date", "projects", "current-project-index"],
  data() {
    return {
      showInfo: false,
      currentTitle: "",
      currentDate: "",
      hoverPreview: false,
      currentHover: {}
    };
  },
  methods: {
    changeProject(e) {
      this.$emit("hovering", false)
      this.currentHover = null
      this.showInfo = false;
      this.$emit("info", this.showInfo);
      this.$emit("changeproject", e);
    },
    toggleInfo() {
      this.showInfo = !this.showInfo;
      this.$emit("info", this.showInfo);
    },
    previewProjectEnter(el, i) {
      document.body.style.overflow = 'hidden'
      this.$emit("hovering", true)
      this.currentHover = i
      this.currentTitle = el.primary.project_title[0].text;
      this.currentDate = el.primary.date;
      this.hoverPreview = true;
    },
    previewProjectLeave() {
      document.body.style.overflow = 'auto'
      this.$emit("hovering", false)
      this.currentHover = null
      this.hoverPreview = false;
    },
  }
};
</script>

<style scoped>
.info-opacity {
  opacity: 0.15;
}

.preview-img {
  position: absolute;
  z-index: 10;
  top: calc(50vh + 24px);
  left: 0;
  width: 100%;
  overflow: hidden;
  user-select: none;
  pointer-events: none;
}

.preview-img .image {
  height: calc(100vh - 100px);
  width: auto;
}

.preview-img .image img {
  max-height: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}


.index-nav {
  user-select: none;
  opacity: 0.2;
}

.info-btn {
  user-select: none;
  transition: opacity ease 500ms;
}

.info-btn:hover {
  cursor: pointer;
  color: #1e1e1e;
}

.index-nav:hover .current-project {
  color: #1e1e1e;
}

.index-nav:hover {
  cursor: pointer;
  /* color: #1e1e1e; */
}

.strikethrough {
  text-decoration: line-through;
}

.current-project {
  color: #1e1e1e !important;
}

@media only screen and (min-width: 768px) {
  .index-nav {
    opacity: 1;
  }

  .index-nav-toggle {
    color: rgb(166, 166, 166);
  }



  .index-nav-toggle-special:hover {
    color: #1e1e1e;
  }



  .info-btn:hover {
    color: rgb(166, 166, 166);
  }
}
</style>