<template>
  <div class="homepage row p-3 d-flex align-items-start">
    <div class="col-12 row">
      <div
        v-for="(link, index) in navigationButtons"
        :key="index"
        class="col-12 mb-1 col-md-auto"
        :class="[link.data.enabled ? 'active-link' : 'not-active-link']"
      >
        <span v-if="link.data.date" class="works-font"
          >{{ link.data.date }} &#8202;</span
        >

        <span
          
          @click="navigateTo(link)"
        >
          {{ link.data.title }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navigationButtons: [],
      spotifyLink: [],
    };
  },
  methods: {
    getContent() {
      this.$prismic.client
        .get({
          predicates: this.$prismic.predicate.any("document.type", [
            "external_link",
            "pictures",
            "category",
          ]),
        })
        .then((response) => {
          this.navigationButtons = response.results.sort((a, b) => {
            const aValue = a.data.homepage_order;
            const bValue = b.data.homepage_order;

            if (aValue < bValue) {
              return -1;
            }
            if (aValue > bValue) {
              return 1;
            }

            return 0;
          });
        });
    },
    navigateTo(link) {
      const nextRoute = link.data.link ? link.data.link : null;
      if (link.data.enabled) {
        if (nextRoute) {
          window.location.href = nextRoute.url;
        } else {
          this.$router.push(link.uid);
        }
      }
    },
  },
  created() {
    this.getContent();
  },
};
</script>

<style scoped lang="scss">
.homepage {
  width: 100%;
  text-transform: uppercase;
}
.active-link,
a {
  color: #1e1e1e;
  text-decoration: none;
}

.active-link:hover,
a:hover {
  color: #1e1e1e;
  text-decoration: none;
  cursor: pointer;
}

.not-active-link {
  color: #1e1e1e;
  text-decoration: line-through;
}
.not-active-link:hover {
  color: #1e1e1e;
  cursor: not-allowed;
}


@media only screen and (min-width: 768px) {
.active-link:hover,
a:hover {
  color: rgb(166, 166, 166);

}


}
</style>
