import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/style/bootstrap.min.css'
import { createPrismic } from '@prismicio/vue'
import ProjectNav from '@/components/ui/ProjectNav'
import ProjectContent from '@/components/ui/ProjectContent'

const prismic = createPrismic({
  endpoint: 'studiosc'
})

const app = createApp(App)
app.component('ProjectNav', ProjectNav)
app.component('ProjectContent', ProjectContent)

app.use(prismic)
app.use(router)
app.mount('#app')
