export function loadImages() {
  
    const images = [...document.querySelectorAll('img.lazy:not(.lazy-done)')]
  
    const setImage = (image) => {
      if (!image.classList.contains('lazy-done')) {
        image.srcset = image.dataset.srcset
        image.classList.add('lazy-done')
      }
    }
  
    images.forEach((image) => {
      if ('IntersectionObserver' in window) {
        const observer = new IntersectionObserver((changes) => {
          changes.forEach((change) => {
            if (change.isIntersecting) {
           
              setImage(image)
              observer.unobserve(image)
            }
          })
        })
  
        observer.observe(image)
      } else {
        setImage(image)
      }
    })
  }
